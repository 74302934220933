/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
.cb-home {
  margin-top: 60px;
}
.desk {
  width: 890px;
}
.cb-mood--around {
  /*
.navigation absolute
*/
  margin-top: 6.2em;
}
.cb-mood--above {
  display: none;
}
.cb-mood--around.cb-mood--around {
  padding: 0;
  background-color: #222;
  min-height: 25em;
  height: calc(100vh - 50px - 235px);
}
.cb-page-layout1 .cb-mood--around {
  padding: 0;
  min-height: 622px;
  max-height: none;
  height: calc(100vh - 50px - 235px);
}
.cb-page-layout6 .cb-mood--around {
  height: 0;
  min-height: 0;
  max-height: none;
  padding-bottom: 51.83333333%;
  background-size: 100%;
}
.cb-mood--around:before {
  /*
at least Layout 1 large (where Text is on above image)
maybe add other darkening layers over other Layouts
*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40em;
  content: '';
  max-width: 100%;
  background-image: -webkit-gradient(linear, left center, right center, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background-image: linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
.cb-page-layout6 .cb-mood--around:before {
  display: none;
}
.cb-page-layout1 .cb-mood > .desk {
  width: 100%;
  max-width: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.cb-mood > .desk {
  /*
needed for north placement
*/
  height: 100%;
}
.cb-page-title {
  margin-top: 31px;
}
.cb-page-layout1 .cb-page-title {
  margin-top: 22px;
}
.cb-page-layout2 .cb-page-title {
  margin-top: 110px;
}
.cb-page-layout2.cb-page-1 .cb-page-title {
  margin-top: 85px;
}
.cb-page-layout3.cb-page-1 .cb-page-title {
  margin-top: 21px;
}
/*
aside is not in page context, therefore cb-pages has margins
*/
#external {
  margin-top: 116px;
}
.footer {
  margin-top: 35px;
}
.navigation {
  margin-top: 21px;
}
.navigation .menu {
  padding-right: 21px;
  padding-left: 22px;
}
.navigation .sub1 > .init {
  margin-left: -22px;
}
.navigation .sub1 > .exit {
  margin-right: -21px;
}
.footer .sub1 {
  margin-left: -25px;
}
.footer .menu {
  padding: 0 21px;
}
.cb-page-layout1 .main {
  /*
Layout 1 & 2
*/
  width: 100%;
}
.cb-page-layout1 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .main h2,
.cb-page-layout1 .main .foot {
  margin-right: 1.63043478%;
  margin-left: 1.63043478%;
}
.cb-page-layout1 .main .part,
.cb-page-layout1 .main > .grid table {
  margin-right: 1.63043478%;
  margin-left: 1.63043478%;
  width: 96.73913043%;
}
.cb-page-layout1 .main .tiny {
  width: 21.73913043%;
}
.cb-page-layout1 .main > .slim {
  width: 25%;
}
.cb-page-layout1 .main > .slim h2,
.cb-page-layout1 .main > .slim .foot,
.cb-page-layout1 .main > .slim .part,
.cb-page-layout1 .main > .slim.grid table {
  margin-right: 6.52173913%;
  margin-left: 6.52173913%;
}
.cb-page-layout1 .main > .slim .part,
.cb-page-layout1 .main > .slim.grid table {
  width: 86.95652174%;
}
.cb-page-layout1 .main > .slim .tiny {
  width: 86.95652174%;
}
.cb-page-layout2 .main {
  width: 100%;
}
.cb-page-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .main h2,
.cb-page-layout2 .main .foot {
  margin-right: 1.63043478%;
  margin-left: 1.63043478%;
}
.cb-page-layout2 .main .part,
.cb-page-layout2 .main > .grid table {
  margin-right: 1.63043478%;
  margin-left: 1.63043478%;
  width: 96.73913043%;
}
.cb-page-layout2 .main .tiny {
  width: 21.73913043%;
}
.cb-page-layout2 .main > .slim {
  width: 50%;
}
.cb-page-layout2 .main > .slim h2,
.cb-page-layout2 .main > .slim .foot,
.cb-page-layout2 .main > .slim .part,
.cb-page-layout2 .main > .slim.grid table {
  margin-right: 3.26086957%;
  margin-left: 3.26086957%;
}
.cb-page-layout2 .main > .slim .part,
.cb-page-layout2 .main > .slim.grid table {
  width: 93.47826087%;
}
.cb-page-layout2 .main > .slim .tiny {
  width: 43.47826087%;
}
.cb-page-layout3 .main {
  /*
unit to unit in large: 20px
no part margins margins but
unit margins to have easier abolute positioning of link in seam
*/
  width: 100%;
}
.cb-page-layout3 .main > .unit {
  margin-right: 1.12359551%;
  margin-left: 1.12359551%;
  width: 97.75280899%;
}
.cb-page-layout3 .main h2,
.cb-page-layout3 .main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout3 .main .part,
.cb-page-layout3 .main > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout3 .main > .slim {
  width: 47.75280899%;
}
.cb-page-layout3 .main > .slim h2,
.cb-page-layout3 .main > .slim .foot,
.cb-page-layout3 .main > .slim .part,
.cb-page-layout3 .main > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout3 .main > .slim .part,
.cb-page-layout3 .main > .slim.grid table {
  width: 100%;
}
.cb-page-layout3 .main > .slim .tiny {
  width: 100%;
}
.cb-page-layout6 .side {
  width: 100%;
}
.cb-page-layout6 .side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout6 .side h2,
.cb-page-layout6 .side .foot {
  margin-right: 1.0989011%;
  margin-left: 1.0989011%;
}
.cb-page-layout6 .side .part,
.cb-page-layout6 .side > .grid table {
  margin-right: 1.0989011%;
  margin-left: 1.0989011%;
  width: 97.8021978%;
}
.cb-page-layout6 .side > .slim {
  width: 33.33333333%;
}
.cb-page-layout6 .side > .slim h2,
.cb-page-layout6 .side > .slim .foot,
.cb-page-layout6 .side > .slim .part,
.cb-page-layout6 .side > .slim.grid table {
  margin-right: 3.2967033%;
  margin-left: 3.2967033%;
}
.cb-page-layout6 .side > .slim .part,
.cb-page-layout6 .side > .slim.grid table {
  width: 93.40659341%;
}
.cb-page-layout6 .side > .slim .tiny {
  width: 93.40659341%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-page-layout1 .main,
.cb-page-layout2 .main {
  /* 
Layout 1 & 2
*/
  margin-left: -1.68539326%;
  width: 103.37078652%;
}
.cb-page-layout1 .main {
  margin-top: 15px;
}
.cb-page-layout3 .main {
  margin-left: -1.12359551%;
  width: 102.24719101%;
  margin-top: 0;
}
.cb-page-layout3.cb-page-1 .main {
  margin-top: 24px;
}
.cb-page-layout4 .side,
.cb-page-layout4 .base {
  width: 48.87640449%;
}
.north.north {
  position: absolute;
  top: 160px;
}
.north.north.north {
  position: absolute;
  width: 400px;
  min-width: 25em !important;
  right: 6%;
  min-width: 0;
  bottom: 4vw;
  top: 0;
  margin-top: 175px;
}
@media (min-width: 1500px) {
  .north.north.north {
    right: 12%;
  }
}
.cb-page-layout1 .main > .seam.wide,
.cb-page-layout2 .main > .seam.wide,
.main > .flat.wide,
.cb-page-layout1 .main > .edge.wide {
  /*
at least
Layout 1 & 2
*/
  margin-left: calc((100vw - 920px) * -.501);
}
.cb-page-layout1 .main > .edge.wide:after,
.main > .flat.wide:after {
  content: '';
  width: 1px;
  position: absolute;
  right: 0;
  top: 1px;
  bottom: 1px;
  z-index: -1;
  box-shadow: 1px 0 0 1px #334350;
}
.cb-page-layout3 .main > .flat.wide {
  margin-left: calc((100vw - 910px) * -.5);
}
/*
at least layout 2
*/
.main > .flat.wide {
  /* 
  Layout 2 & 3, pure + in 2, edge + in 3
  inconsistent, taken from layout2 assuming «Angebot zählt»
*/
  margin-top: 102px;
  margin-bottom: 4px;
  padding-bottom: 41px;
}
.cb-page-layout1 .main > .edge.wide + .flat.wide {
  margin-top: 0;
}
.main > .flat.wide .head {
  margin-top: 40px;
}
/*.main > .flat.wide h2,*/
.cb-page-layout1 .main > .seam.wide h2,
.cb-page-layout2 .main > .seam.wide h2 {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.14285714;
  /*
max-width for layout2 large
*/
  margin-right: auto;
  margin-left: auto;
  max-width: 24em;
}
.flat.wide .body {
  /*
if there's no h2
*/
  margin-top: 3em;
}
.flat.wide .head + .body {
  margin-top: 0.4em;
}
/*
used in both Layout 1 & Layout 2
*/
.cb-page-layout1 .main > .pure.wide .link:only-child,
.cb-page-layout2 .main > .pure.wide .link:only-child {
  margin-top: 0.9em;
  margin-bottom: 42px;
  font-size: 16px;
  font-size: 1.6rem;
}
.cb-page-layout1 .main > .pure.wide .link:only-child {
  margin-top: 2em;
}
.main .slim {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
}
.main .slim h3 {
  font-size: 16px;
  font-size: 1.6rem;
}
#view .cb-page-layout1 .main > .seam.wide .body,
#view .cb-page-layout2 .main > .seam.wide .body {
  max-width: 890px;
  float: none;
  margin-right: auto;
  margin-left: auto;
}
/*
.show has aspect in small -> large
.show is above content in small & medium
.show is background of unit in large
*/
.cb-page-layout1 .main > .edge.wide {
  text-align: left;
  min-height: 540px;
  padding: 0 !important;
}
@media (min-width: 1200px) {
  #view .cb-page-layout1 .main > .edge.wide {
    min-height: 540px;
    min-height: 45vw;
  }
}
#view .cb-page-layout1 .main > .edge.wide .head {
  padding-top: 0;
}
.cb-page-layout1 .main > .edge.wide h2,
.cb-page-layout1 .main > .edge.wide .part,
.cb-page-layout1 .main > .edge.wide .foot {
  margin: 0;
}
#view .cb-page-layout1 .main > .edge.wide h2 {
  text-shadow: 3px 3px 7px #000;
}
#view .cb-page-layout1 .main > .edge.wide .head {
  margin-top: 221px;
}
.cb-page-layout1 .main > .edge.wide .part {
  width: 100%;
}
#view .cb-page-layout1 .main > .edge.wide .head,
#view .cb-page-layout1 .main > .edge.wide .body,
#view .cb-page-layout1 .main > .edge.wide .foot {
  float: right;
  clear: right;
  width: 400px;
  min-width: 25em !important;
  margin-right: 6%;
}
@media (min-width: 1500px) {
  #view .cb-page-layout1 .main > .edge.wide .head,
  #view .cb-page-layout1 .main > .edge.wide .body,
  #view .cb-page-layout1 .main > .edge.wide .foot {
    margin-right: 12%;
  }
}
#view .cb-page-layout1 .main > .edge.wide .show {
  height: 100%;
  padding: 0;
  z-index: 0;
}
#view .cb-page-layout1 .main > .edge.wide .show:before {
  /*
at least Layout 1 large (where Text is on above image)
maybe add other darkening layers over other Layouts
*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  content: '';
  z-index: 1;
  max-width: 100%;
  background-image: -webkit-gradient(linear, left center, right center, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background-image: linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
.cb-page-layout1 .main > .edge.wide .show img {
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
  min-height: 100%;
}
.cb-page-layout1 .main > .edge.wide h3 {
  font-size: 26px;
  font-size: 2.6rem;
}
.cb-page-layout2 .main .part {
  margin-top: 1.6em;
  margin-bottom: 1.6em;
}
.cb-page-layout3 .main > .wide .show + .lead {
  /*
given to all units but really used on edge (sic)
*/
  margin-top: 2em;
}
.cb-page-layout3 .main > .edge .link > .open {
  margin-top: 1em;
}
.north > .unit {
  background: none;
  text-align: left;
}
.north h3 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.34615385;
}
.north .lead + .link {
  margin-top: 50px;
  margin-bottom: 18px;
}
.north .link > .open {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0.9em 1.3em;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  position: relative;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.shop,
#disp.cb-shoppingcart {
  left: 50%;
  margin-left: -375px;
  width: 750px;
}
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/******* module-shop-large.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 32%;
}
#disp.shop textarea {
  width: 67%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 67%;
}
#disp.shop input.zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city,
#disp.shop .cb-shop-currency > select,
#disp.shop .cb-shop-countries > select,
#disp.shop .cb-shop-regions > select {
  width: 55%;
}
#disp.shop input.city {
  width: 56%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
.cb-shipping-range-label-input,
.cb-shipping-range-value-input {
  width: 100% !important;
}
#disp .cb-shop-country > select {
  width: 67%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-large.css.map */